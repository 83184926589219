<div
	class="card h-100 border-0"
	*ngIf="group"
	(click)="choosenGroup(group.id)"
	id="{{ group.id }}"
>
	<button
		*ngIf="(group.adUrl || group.url) && admin"
		style="
			background-color: transparent;
			border-color: transparent;
			position: absolute;
			margin-top: 1%;
			margin-left: 85%;
			z-index: 2;
		"
		class="text-success"
		(click)="redirectToUrl(group.url ? group.url : group.adUrl)"
	>
		<svg class="bi" width="32" height="35" fill="currentColor">
			<use xlink:href="assets/icons/bootstrap-icons.svg#link-45deg" />
		</svg>
	</button>

	<div class="card-img-top rounded-4" *ngIf="group.photos">
		<div style="margin-top: -300px" *ngIf="group.owner?.isPremium">
			<div
				class="bg-success position-absolute mx-auto btn btn-circle rounded-circle"
				style="width: 90px; height: 90px; z-index: 1"
			>
				<i
					class="bi bi-person-check-fill text-white mt-1 display-3"
				></i>
			</div>
		</div>
		<ng-container *ngIf="!group.photos[0]">
			<picture class="w-100 h-100">
				<img
					loading="lazy"
					ngSrc="/assets/img/defaultGroupPicture.png"
					width="25"
					height="20"
					class="lazyload"
					alt="Photo par default de la colocation{{
						group.town ? ' à ' + group.town : ''
					}} : {{ group?.title }}"
				/>
			</picture>
		</ng-container>
		<ng-container *ngIf="group.photos[0]">
			<img
				loading="lazy"
				*ngIf="!group?.url"
				ngSrc="/{{ group.photos[0] | getPhotos : group.id : 'groups' }}"
				width="25"
				height="20"
				class="lazyload"
				src-fallback="assets/img/defaultGroupPicture.png"
				alt="Photo de la colocation{{
					group.town ? ' à ' + group.town : ''
				}} : {{ group?.title }}"
			/>
			<img
				loading="lazy"
				*ngIf="group?.url"
				ngSrc="/{{
					group.photos[0].replace('.webp', '_thumbnail.webp')
				}}"
				width="25"
				height="20"
				class="lazyload"
				src-fallback="assets/img/defaultGroupPicture.png"
				alt="defaultGroupPicture"
			/>
		</ng-container>
	</div>
	<!-- Owner photo -->
	<div class="card-img-overlay" *ngIf="group.owner?.id || group.owner?._id">
		<a
			(click)="setSaveBack(group.id)"
			[routerLink]="
				lang === ''
					? ['/colocation', group.id]
					: [lang, 'colocation', group.id]
			"
		>
			<div class="h-100 position-relative">
				<app-round-image
					class="position-absolute bottom-0 border border-3 rounded-circle border-white bg-white"
					*ngIf="group.owner?.profilePicture"
					src="{{
						group.owner?.profilePicture.replace(
							'.webp',
							'_thumbnail.webp'
						)
					}}"
					src-fallback="{{ group.owner?.profilePicture }}"
					alt="{{ group.owner?.firstName }}"
				>
				</app-round-image>
				<app-round-image
					class="position-absolute bottom-0 border border-3 rounded-circle border-white bg-white"
					*ngIf="!group.owner?.profilePicture"
					src="assets/img/defaultProfilePicture.png"
					src-fallback="assets/img/defaultProfilePicture.png"
					alt="{{ group.owner.firstName }}"
				>
				</app-round-image>
			</div>
		</a>
	</div>

	<div class="card-img-overlay" *ngIf="!group.owner">
		<a
			(click)="setSaveBack(group.id)"
			[routerLink]="
				lang === ''
					? ['/colocation', group.id]
					: [lang, 'colocation', group.id]
			"
		>
			<div class="h-100 position-relative"></div>
		</a>
	</div>

	<div class="p-0 card-body">
		<div class="card-title">
			<div class="row">
				<div class="col-9">
					<div
						class="overflow-hidden text-nowrap"
						style="text-overflow: ellipsis"
					>
						<ng-container *ngIf="group.town || group.address">
							<i
								class="bi bi-geo-alt-fill"
								aria-hidden="true"
							></i>
							<span class="ms-2">
								{{
									(!group.hasFlat
										? 'card.group.group'
										: group.roomsFree == group.rooms.length
										? 'card.group.flat'
										: 'card.group.flatShare'
									) | translate
								}}
								{{
									'card.group.where'
										| translate
											: {
													city: group.town
														? group.town
														: this.shownAddress
											  }
								}}
							</span>
						</ng-container>
					</div>
				</div>
				<div class="col-3">
					<div class="float-end">
						{{ group.budget | currency : 'EUR' }}
					</div>
				</div>
			</div>
		</div>
		<div class="card-text">
			<a
				(click)="setSaveBack(group.id)"
				class="text-decoration-none"
				[routerLink]="
					lang === ''
						? ['/colocation', group.id]
						: [lang, 'colocation', group.id]
				"
			>
				<h2 class="mb-2 h5 one-line-title" *ngIf="title">
					{{ title }}
				</h2>
			</a>
			<div>
				<h3 class="lead text-muted description-container">
					{{ description }}
				</h3>
				<div
					*ngIf="group.availabilityStarts"
					class="groupAvailabilityStarts mb-2 lead text-muted"
				>
					<svg
						class="bi"
						width="20"
						height="23.5"
						fill="currentColor"
					>
						<use
							xlink:href="assets/icons/bootstrap-icons.svg#calendar4-week"
						/>
					</svg>
					<span class="ms-2" *ngIf="asSoonAsPossible" translate
						>card.group.asap</span
					>
					<span class="ms-2" *ngIf="!asSoonAsPossible" translate>
						{{
							'card.group.disponibility'
								| translate
									: {
											date:
												group.availabilityStarts
												| date : 'dd/MM/yy'
									  }
						}}
					</span>
				</div>
			</div>
			<ng-content></ng-content>
		</div>
	</div>
</div>
