import { Conversation } from './conversation.model'
import { User } from './user.model'

export class Group {
	public id: string
	public _id: string

	public title: string
	public description: string
	public lang: {
		en: {
			title: string
			description: string
		}
	}

	public owner: User

	public roomsFree: number
	public rooms: any[]

	public candidates: any[]
	public conversations: Conversation[]
	public members: User[]
	public adUrl: string

	public createdAt: Date
	public availabilityStarts: Date
	public availabilityEnds: Date

	public budget: number
	public address: string
	public vicinity: string
	public lat: number
	public lng: number
	public numProposeMail: number
	public numClickExtern: number
	public photos: string[]
	public isActive: boolean
	public isBan: boolean
	public views: number
	public location: any

	public hasFlat: boolean
	public hasFurniture: boolean

	public url: string
	public town: string
	public validOnly: boolean
	public icon: {
		url: string
		scaledSize: { height: number; width: number }
		labelOrigin: { x: number; y: number }
	}

	public totalArea: number

	public ubiflow: any

	public isLocked: boolean

	private _json: any

	public toJSON() {
		return this._json
	}

	public constructor(group, deep = true) {
		const {
			_id = null,
			title = null,
			description = null,
			lang = null,
			owner = null,
			address = null,
			roomsFree = null,
			rooms = null,
			adUrl = null,
			candidates = null,
			conversations = null,
			createdAt = null,
			members = null,
			budget = null,
			isActive = null,
			isBan = null,
			views = null,
			location = null,
			numProposeMail = null,
			numClickExtern = null,
			photos = null,
			availabilityStarts = null,
			availabilityEnds = null,
			hasFlat = null,
			reactivateAt = null,
			url = null,
			town = null,
			validOnly = null,
			icon = null,
			totalArea = null,
			ubiflow = null,
			isLocked = false,
		} = group

		this._json = group

		this.id = _id
		this._id = _id
		this.title = title
		this.description = description
		this.lang = lang
		this.address = address
		this.vicinity = location && location.vicinity
		this.location = location
		if (!this.location?.postalCode) {
			const city = this.address?.split(', ')
			if (city?.length === 3) {
				const postalCode = city[1]?.split(' ')?.shift()
				this.location['postalCode'] = postalCode
			}
		}
		this.lng = location && location.coordinates[0]
		this.lat = location && location.coordinates[1]
		this.roomsFree = roomsFree
		this.adUrl = adUrl
		this.rooms = rooms && [...rooms]
		this.budget = budget
		this.isActive = isActive
		this.isBan = isBan
		this.views = views
		this.numProposeMail = numProposeMail
		this.numClickExtern = numClickExtern
		this.photos = photos
		this.availabilityStarts =
			availabilityStarts && new Date(availabilityStarts)
		this.availabilityEnds = availabilityEnds && new Date(availabilityEnds)
		this.hasFlat = hasFlat
		this.url = url
		this.town = town
		this.validOnly = validOnly
		this.createdAt =
			reactivateAt && new Date(reactivateAt) > new Date(createdAt)
				? new Date(reactivateAt)
				: new Date(createdAt)
		this.icon = icon
		this.totalArea = totalArea

		this.ubiflow = ubiflow
		this.isLocked = isLocked

		if (deep) {
			this.owner = owner && new User(owner, false)
			this.candidates =
				candidates &&
				candidates.map((c) => {
					return {
						user: c.user ? new User(c.user, false) : null,
						startDate: new Date(c.startDate),
						endDate: c.endDate,
						price: c.price,
					}
				})
			this.conversations =
				conversations &&
				conversations.map((c) => new Conversation(c, false))
			this.members = members && members.map((m) => new User(m, false))
		} else {
			this.owner = owner && { ...owner }
			this.candidates = candidates && [...candidates]
			this.conversations = conversations && [...conversations]
			this.members = members && [...members]
		}
	}
}
