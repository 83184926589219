import { ErrorHandler } from '@angular/core'
import { environment } from '@src/environments/environment'

export class ErrorLogger implements ErrorHandler {
	static initWith(sentry: any) {
		return () => new ErrorLogger(sentry)
	}

	constructor(private sentry: any) {
		if (environment.production) {
			// Sentry PROD
			this.sentry.init({
				ignoreErrors: ['Non-Error exception captured'],
				dsn: 'https://5d4e5c973e2b490ca0af709ee8e6fa72@o110935.ingest.us.sentry.io/4505090862546944',
				tracesSampleRate: 0.5,
				// Session Replay
				replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
				replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			})
		} else if (!environment.LOCAL) {
			// Sentry DEV
			this.sentry.init({
				dsn: 'https://b6d060f52f0fa92d186dcc8862cba427@o4506296904253440.ingest.sentry.io/4506297721552896',
			})
		}
	}

	handleError(error: any): void {
		const chunkFailedMessage = /Loading chunk/
		const tawkScriptPattern = /twk/
		const extensionErrorPattern = /chrome-extension/
		// Check for errors to ignore
		if (error.stack && tawkScriptPattern.test(error.stack)) {
			console.warn('Ignored Tawk script error: ', error)
			return // Early return, ignoring the Tawk script error
		}
		// Check for errors from browser extensions to ignore
		if (extensionErrorPattern.test(error.message)) {
			console.warn('Ignored browser extension error: ', error)
			return // Early return, ignoring the browser extension error
		}
		if (chunkFailedMessage.test(error.message)) {
			window.location.reload()
		} else {
			if (environment.production) {
				// Sentry PROD
				this.sentry.captureException(
					error.originalError || error.message || error.error || error
				)
			} else if (!environment.LOCAL) {
				// Sentry DEV
				this.sentry.captureException(
					error.originalError || error.message || error.error || error
				)
			}
		}
		throw error // for default behaviour rather than silentely dying
	}
}
