import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'getPhotos',
	pure: true,
})
export class GetPhotosPipe implements PipeTransform {
	public transform(path: string, ...args): string {
		return path.startsWith('public/')
			? path.replace('.webp', '_thumbnail.webp')
			: `public/${args[1]}/${args[0]}/${path.replace(
					'.webp',
					'_thumbnail.webp'
			  )}`
	}
}
