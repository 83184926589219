import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http'
import { Observable, of, timer } from 'rxjs'
import { catchError, tap, retry } from 'rxjs/operators'
import { isPlatformBrowser } from '@angular/common'
import { UserRestService } from './services/rest/user/user-rest.service'

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
	private cache: { [key: string]: any } = {}
	private isBrowser: boolean = false

	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private userRest: UserRestService
	) {
		this.isBrowser = isPlatformBrowser(this.platformId)
	}

	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const cacheKey = request.urlWithParams

		// Check if the request is eligible for caching
		if (request.method === 'GET') {
			const cachedResponse = this.cache[cacheKey]

			// If the cached response exists, return it
			if (cachedResponse) {
				const response = new HttpResponse({
					body: cachedResponse,
					status: 200,
				})
				return of(response)
			}
		}

		return next.handle(request).pipe(
			// Retry logic with a delay (2000ms) and maximum of 3 retries for status 0 errors
			retry({
				count: 3,
				delay: (error: HttpErrorResponse) => {
					// Retry only if it's a network error (status 0)
					if (error.status === 0) {
						return timer(2000) // Wait for 2 seconds before retrying
					}
					throw new HttpErrorResponse(error) // Do not retry for other errors
				},
			}),
			tap((event) => {
				// Cache the response if it's a successful GET request
				if (
					event instanceof HttpResponse &&
					request.method === 'GET' &&
					!this.isBrowser
				) {
					this.cache[cacheKey] = event.body
				}
			}),
			catchError(async (error) => {
				if (error.status === 401) {
					// Logout user
					this.userRest.currentUser.next(null)
					if (this.isBrowser) {
						localStorage.removeItem('loggedInUser')
						localStorage.removeItem('id_token')
						localStorage.removeItem('expires_in')
						localStorage.setItem(
							'logoutEvent',
							Date.now().toString()
						)
						window.location.reload()
					}
				} else {
					throw new HttpErrorResponse(error)
				}
			})
		) as Observable<HttpEvent<any>>
	}
}
